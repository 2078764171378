@import '../../../../../assets/scss/color';

.infomation-section .ant-form-item .ant-form-item-label {
  margin-right: 10px;
}

.infomation-section .ant-row.ant-form-item-row {
  justify-content: space-between;
  align-items: baseline;
}

.infomation-section .ant-picker {
  width: 100%;
}

.infomation-section .ant-form-item .ant-form-item-control {
  width: 180px;
}

@media only screen and (max-width: 1280px) {
  .infomation-section {
    flex-wrap: wrap;
  }
}

@media only screen and (min-width: 1281px) and (max-width: 1440px) {
  .infomation-section .ant-picker {
    width: 100%;
  }

  .infomation-section .ant-form-item .ant-form-item-control {
    width: 150px;
  }
}

.red-border {
  border: 1px solid red;
}

.modal .ant-table-wrapper .ant-table-thead > tr > th {
  font-size: 0.875rem;
}

.custom-radio .ant-radio-inner {
  background-color: #fff !important;
}

.custom-radio .ant-radio-inner {
  background-color: #fff !important;
}

.custom-radio .ant-radio-inner::after {
  background-color: $secondary;
  width: 20px;
  height: 20px;
  margin-block-start: -10px;
  margin-inline-start: -10px;
}

input[type='radio'] {
  border: 0px;
  width: 100%;
  height: 1.5em;
}

.custom-common-modal.ant-table-wrapper .ant-table-thead > tr > th {
  padding: 10px 0;
  background-color: #efefef;
  text-align: center;
  color: #505050;
}

.custom-common-modal.ant-table-wrapper
  .ant-table-tbody
  > tr:nth-child(even)
  > td {
  background-color: #f4f4f4;
  padding: 0;
}

.attendu-custom-search.custom-search {
  width: 300px;
  margin-left: 10px;
  .ant-select-selector {
    height: 24px !important;
    .ant-select-selection-search-input {
      height: 20px;
    }
  }
}

.main-content-mission .ant-btn {
  height: 24px;
  line-height: 14px;
  font-size: 14px !important;
}

.ant-form-horizontal .ant-form-item-control {
  flex: none !important;
}

.supprimer::before {
  content: url('../../../../../assets/icons/btn-delete.svg');
  position: absolute;
  left: 10px;
}

.envoyer::before {
  content: url('../../../../../assets/icons/tick-green.svg');
  position: absolute;
  left: 10px;
}

.remetre::before {
  content: url('../../../../../assets/icons/remetre.svg');
  position: absolute;
  left: 10px;
}

.custom-common-modal.ant-table-wrapper
  .ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-content
  > table,
.custom-common-modal.ant-table-wrapper
  .ant-table.ant-table-bordered
  > .ant-table-container {
  border-top: none;
}

table.table-document {
  width: 100%;
}

.table-document thead {
  background-color: $blocked_input;
  height: 40px;
  color: $gray;
  border-bottom: 1px solid $gray;
  border-right: 1px solid $gray;
  font-weight: 600;
  font-size: 16px;
}

.table-document td {
  background-color: #f9f9f9;
  height: 40px;
  color: $gray;
  border-bottom: 1px solid $blocked_input;
  border-right: 1px solid $blocked_input;
  text-align: center;
  font-weight: 500;
}

.table-document .view {
  content: url('../../../../../assets/icons/View.svg');
  cursor: pointer;
}

.table-document .export {
  content: url('../../../../../assets/icons/Download.svg');
  cursor: pointer;
}

.table-document .printer {
  content: url('../../../../../assets/icons/Blue-printer.svg');
  cursor: pointer;
}

.table-document .delete {
  content: url('../../../../../assets/icons/icon-delete.svg');
  cursor: pointer;
}

.custom-common-modal.ant-table-wrapper .ant-table-container {
  background-color: #fbfbfb;
}

.main-content::-webkit-scrollbar {
  width: 15px;
}

.main-content::-webkit-scrollbar-track {
  border: 4px solid rgba(0, 0, 0, 0);
  background-clip: padding-box;
  border-radius: 9999px;
  background-color: #dbdbdb;
}

.main-content::-webkit-scrollbar-thumb {
  border: 4px solid rgba(0, 0, 0, 0);
  background-clip: padding-box;
  border-radius: 9999px;
  background-color: $secondary;
}

.main-content-2::-webkit-scrollbar {
  width: 15px;
}

.main-content-2::-webkit-scrollbar-track {
  border: 4px solid rgba(0, 0, 0, 0);
  background-clip: padding-box;
  border-radius: 9999px;
  background-color: #dbdbdb;
}

.main-content-2::-webkit-scrollbar-thumb {
  border: 4px solid rgba(0, 0, 0, 0);
  background-clip: padding-box;
  border-radius: 9999px;
  background-color: $secondary;
}

.main-content-mission::-webkit-scrollbar {
  width: 15px;
}

.main-content-mission::-webkit-scrollbar-track {
  border: 4px solid rgba(0, 0, 0, 0);
  background-clip: padding-box;
  border-radius: 9999px;
  background-color: #dbdbdb;
}

.main-content-mission::-webkit-scrollbar-thumb {
  border: 4px solid rgba(0, 0, 0, 0);
  background-clip: padding-box;
  border-radius: 9999px;
  background-color: $secondary;
}

.infomation-section .ant-picker {
  height: 24px !important;
}

.infomation-section .ant-input {
  height: 24px;
}

.infomation-section .ant-select-selector .ant-select-selection-item {
  line-height: 25px;
}

.infomation-section .ant-select-selector {
  height: 24px !important;
}

.custom-common-modal.ant-table-wrapper .ant-table-tbody > tr > td {
  background-color: #f9f9f9;
  padding: 10px 0;
  text-align: center;
}
