.pd {
  &-10-15{
    padding: 10px 15px;
  }
  &-bot {
    &-15 {
      padding-bottom: 15px;
    }
    &-30 {
      padding-bottom: 30px;
    }
  }
  &-left{
    &-34{
      padding-left: 34px;
    }
  }


  &-vertical-34{
    padding-top: 34px;
    padding-bottom: 34px;
  }
}

.gap {
  &-9 {
    height: 9px;
  }
  &-10{
    height: 10px;
  }
  &-18 {
    height: 18px;
  }
  &-28 {
    height: 28px;
  }
  &-32{
    height: 32px;
  }
  &-44 {
    height: 44px;
  }
}

.gap-hor {
  &-4 {
    display: inline-block;
    width: 4px;
  }
  &-8 {
    width: 8px;
  }

  &-36 {
    width: 36px;
  }
}
