/* noti */
.noti.ant-notification-notice {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 0;
}

.noti.noti-error.ant-notification-notice {
  background: #ffdcd1;
  border: 1px solid #f25524;
}

.noti.noti-success.ant-notification-notice {
  background: #c6ffe4;
  border: 1px solid #01e37a;
}

.noti .ant-notification-notice-message {
  font-size: 14px;
  margin-left: 10px;
  margin-right: 10px;
}

.noti.noti-error .ant-notification-notice-message {
  color: #f25524;
}

.noti.noti-sucess .ant-notification-notice-message {
  color: #007d43;
}

.noti.ant-notification-notice .ant-notification-notice-close {
  width: 0;
  height: 0;
  position: relative;
  top: -5px;
  right: 20px;
}

.ant-notification-notice .ant-notification-notice-close {
  position: absolute;
}
