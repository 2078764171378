@import '../scss/color';
.bg-cl-primary {
  background-color: $primary;
}
.bd-cl-red{
  border-color: #F25524;
}

.bg-cl-gray{
  background-color: #fbfbfb;
}

.txt-cl-red{
  color: #F25524;
}

