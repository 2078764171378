.fixed-bottom {
  overflow: hidden;
  position: fixed;
  bottom: 0;
  width: 90%;
  border-top: 1px solid #b7b7b7;
  padding: 20px;
}

.bd-bot-1-gray-history {
  border-bottom: 1px solid #b7b7b7;
}
