@import '../../assets/scss/_color.scss';

@mixin text($font-size, $font-weight, $line-height) {
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: $font-weight;
  font-size: $font-size;
  line-height: $line-height;
  letter-spacing: -0.014em;
  color: #20458f;
}

.modal-title-heading-1 {
  @include text(28px, 700, 34px);
}
.modal-title-heading-2 {
  @include text(24px, 400, 29px);
  text-transform: capitalize;
}

.page-title-heading-1 {
  @include text(20px, 600, 30px);
  text-transform: uppercase;
}

.page-title-heading-section-modal {
  @include text(18px, 700, 22px);
}

.form-label {
  @include text(16px, 600, 19.5px);
  color: $gray;
  margin-top: 3px;
}

@media only screen and (max-width: 1500px) {
  .form-label {
    @include text(14px, 600, 16px);
    color: $gray;
  }

  .text-header-table {
    @include text(14px, 600, 17px);
    text-align: center;
    color: $gray;
  }
}

.unit-table {
  @include text(12px, 500, 15px);
  color: #767676;
}

.text-header-table {
  @include text(16px, 600, 17px);
  text-align: center;
  letter-spacing: -0.014em;
  color: #505050;
}
.text-btn-submit-last-modal {
  @include text(18px, 600, 22px);
  color: white;
}
.text-title-popup {
  @include text(18px, 500, 15px);
}
.text-gray-popup {
  @include text(14px, 500, 15px);
  color: #848484;
}

.tick-message {
  font-family: 'Montserrat', serif;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  /* identical to box height */

  letter-spacing: -0.014em;

  color: #01e37a;
}
