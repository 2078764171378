$primary: #20458f;
$secondary: #0189e3;
$red: #f25524;
$red1: #ffdcd1;
$green: #01e37a;
$green1: #c6ffe4;
$gray: #505050;
$gray3: #b7b7b7;
$blocked_input: #efefef;
$border_table: #e5e5e5;
$border_bottom_header_dropdown: #6f98eb;
$active_menu: #f09637;
$border_bottom_select_list_item: #d9d9d9;
$border_header_table: #707070;
$border_children_cell_table: #707070;
$border_input: #bebebe;
$placeholder_input: #808080;
