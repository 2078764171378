@import '../../../assets/scss/color';

.modal .ant-collapse-header-text {
  color: $primary;
  font-weight: 700;
  font-size: 18px;
}

.modal .ant-collapse-arrow {
  font-size: 18px !important;
  margin-top: 25px;
}

.modal.ant-modal {
  width: calc(100% - 190px) !important;
  margin: 0 auto;
  padding-bottom: 0;
}

.ant-form-item .ant-form-item-label > label {
  flex-direction: row-reverse !important;
  font-weight: 600;
  font-size: 1rem;
  color: $gray;
}

@media only screen and (max-width: 1500px) {
  .ant-form-item .ant-form-item-label > label {
    font-size: 14px;
  }
}

.modal .ant-table-wrapper .ant-table-thead > tr > th {
  font-size: 1rem;
}

.modal.ant-modal .ant-modal-close-x {
  font-size: 28px;
  margin-left: -20px;
  margin-top: 10px;
}

.modal.ant-modal .ant-modal-close-x,
.modal.ant-modal .ant-modal-close {
  &:hover {
    background-color: #fff;
  }
}

.ant-form-item .ant-form-item-control {
  flex-grow: unset !important;
}

.ant-modal .ant-modal-footer {
  border-top: 1px solid $gray3;
  padding-top: 8px;
}

button:hover {
  color: transparent;
}
.modal.ant-modal ::-webkit-scrollbar {
  height: 8px;
  width: 8px;
}

.modal.ant-modal ::-webkit-scrollbar-track {
  background-color: #dbdbdb;
  border: solid 20px transparent;
}

.modal.ant-modal ::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: $secondary;
  border: solid 20px transparent;
}
.custom-common-modal.ant-table-wrapper .ant-table-tbody > tr > td {
  padding: 5px 0;
}
